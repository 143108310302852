<script setup lang="ts">
import { ValidationError } from "@api/errors"
import useVuelidate from '@vuelidate/core'
import { email, required } from '@vuelidate/validators'
import { useAuthStore } from '@/stores/auth'
import { useGenerateImageVariant } from '@core/composable/useGenerateImageVariant'
import { VNodeRenderer } from '@layouts/components/VNodeRenderer'
import { themeConfig } from '@themeConfig'

import authV2ForgotPasswordIllustrationDark from '@images/pages/auth-v2-forgot-password-illustration-dark.png'
import authV2ForgotPasswordIllustrationLight from '@images/pages/auth-v2-forgot-password-illustration-light.png'
import authV2MaskDark from '@images/pages/misc-mask-dark.png'
import authV2MaskLight from '@images/pages/misc-mask-light.png'

const authStore = useAuthStore()

const state = reactive({
  email: '',
})

const rules = {
  email: [required, email],
}

const $externalResults = ref({})

const v$ = useVuelidate(rules, state, { $externalResults })

const loading = ref(false)
const error = ref('')
const message = ref('')

const authThemeImg = useGenerateImageVariant(authV2ForgotPasswordIllustrationLight, authV2ForgotPasswordIllustrationDark)

const authThemeMask = useGenerateImageVariant(authV2MaskLight, authV2MaskDark)

definePage({
  meta: {
    layout: 'blank',
    unauthenticatedOnly: true,
  },
})

const submit = async () => {
  try {
    loading.value = true
    error.value = ''
    message.value = ''

    const res = await authStore.forgotPassword(state)

    console.log(res)
    message.value = res.message

    loading.value = false
    state.email = ''
    v$.value.$reset()
  }
  catch (err) {
    if (err instanceof ValidationError) {
      error.value = err.message
      $externalResults.value = err.errors ?? {}
    }

    loading.value = false
  }
}
</script>

<template>
  <VRow
    class="auth-wrapper bg-surface"
    no-gutters
  >
    <VCol
      lg="8"
      class="d-none d-lg-flex"
    >
      <div class="position-relative bg-background rounded-lg w-100 ma-8 me-0">
        <div class="d-flex align-center justify-center w-100 h-100">
          <VImg
            max-width="368"
            :src="authThemeImg"
            class="auth-illustration mt-16 mb-2"
          />
        </div>

        <VImg
          class="auth-footer-mask"
          :src="authThemeMask"
        />
      </div>
    </VCol>

    <VCol
      cols="12"
      lg="4"
      class="d-flex align-center justify-center"
    >
      <VCard
        flat
        :max-width="500"
        class="mt-12 mt-sm-0 pa-4"
      >
        <VCardText>
          <VNodeRenderer
            :nodes="themeConfig.app.logo"
            class="mb-6"
          />
          <h4 class="text-h4 mb-1">
            Forgot Password? 🔒
          </h4>
          <p class="mb-0">
              Enter your email and we'll send you instructions to reset your password
          </p>
        </VCardText>

        <VCardText>
          <VForm @submit.prevent="submit">
            <VScaleTransition>
              <VRow v-if="message">
                <VCol cols="12">
                  <VAlert

                    variant="tonal"
                    color="success"
                  >
                    {{ message }}
                  </VAlert>
                </VCol>
              </VRow>
            </VScaleTransition>
            <VScaleTransition>
              <VRow v-if="error">
                <VCol cols="12">
                  <VAlert

                    variant="tonal"
                    color="error"
                  >
                    {{ error }}
                  </VAlert>
                </VCol>
              </VRow>
            </VScaleTransition>
            <VRow>
              <!-- email -->
              <VCol cols="12">
                <AppTextField
                  v-model="v$.email.$model"
                  :field="v$.email"
                  :disabled="loading"
                  autofocus
                  label="Email"
                  type="email"
                  placeholder="johndoe@email.com"
                />
              </VCol>

              <!-- Reset link -->
              <VCol cols="12">
                <VBtn
                  block
                  type="submit"
                  :loading="loading"
                >
                  Send Reset Link
                </VBtn>
              </VCol>

              <!-- back to login -->
              <VCol cols="12">
                <RouterLink
                  class="d-flex align-center justify-center"
                  :to="{ name: 'login' }"
                >
                  <VIcon
                    icon="tabler-chevron-left"
                    class="flip-in-rtl"
                  />
                  <span>Back to login</span>
                </RouterLink>
              </VCol>
            </VRow>
          </VForm>
        </VCardText>
      </VCard>
    </VCol>
  </VRow>
</template>

<style lang="scss">
@use "@core-scss/template/pages/page-auth.scss";
</style>
